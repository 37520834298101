import React, { createContext, useState, useEffect, useContext, useRef } from 'react';

const THEME_LIGHT = 'light';
const THEME_DARK = 'dark';
const THEME_DEVICE = 'device';

const ThemeContext = createContext();



  

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(localStorage.getItem('data-theme') || THEME_DEVICE);
    const [selectedTheme, setSelectedTheme] = useState(localStorage.getItem('data-selected-theme') || localStorage.getItem('data-theme') || THEME_DEVICE);

    const selectedThemeRef = useRef(selectedTheme);

    useEffect(() => {
        const handleColorSchemeChange = () => {
            const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
            if (selectedThemeRef.current === THEME_DEVICE) {
                setTheme(prefersDarkScheme ? THEME_DARK : THEME_LIGHT);
            }
        };

        if (selectedThemeRef.current === THEME_DEVICE) {
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleColorSchemeChange);
        } else {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handleColorSchemeChange);
        }

        selectedThemeRef.current = selectedTheme;

        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handleColorSchemeChange);
        };

    }, [selectedTheme, theme])

    useEffect(() => {
            
        if (theme === THEME_DEVICE) {
            const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
            applyTheme(prefersDarkScheme ? THEME_DARK : THEME_LIGHT);
            setCustomProperties(prefersDarkScheme ? THEME_DARK : THEME_LIGHT)
        } else {
            applyTheme(theme);
            setCustomProperties(theme)
        }

    }, [theme]);

    const applyTheme = (theme) => {
        document.documentElement.setAttribute('data-theme', theme);
        document.querySelector('html').setAttribute('data-theme', theme);
        localStorage.setItem('data-theme', theme);
    };

    const toggleTheme = (newTheme) => {
        localStorage.setItem('theme-set-user', 'true');
        localStorage.setItem('data-selected-theme', newTheme);
        setTheme(newTheme);
        setSelectedTheme(newTheme);
    };


    function setCustomProperties(theme) {
        const root = document.documentElement;
      
        const themePrefix = theme === 'dark' ? 'REACT_APP_DARK_' : 'REACT_APP_LIGHT_';
      
        root.style.setProperty('--brand-color', process.env[`${themePrefix}BRAND_COLOR`]);
        root.style.setProperty('--primary-color', process.env[`${themePrefix}PRIMARY_COLOR`]);
        root.style.setProperty('--texthighlight-color', process.env[`${themePrefix}TEXT_HIGHLIGHT_COLOR`]);
        root.style.setProperty('--tertiary-color', process.env[`${themePrefix}TERTIARY_COLOR`]);
        root.style.setProperty('--secondary-color', process.env[`${themePrefix}SECONDARY_COLOR`]);
        root.style.setProperty('--gray-color', process.env[`${themePrefix}GRAY_COLOR`]);
        root.style.setProperty('--grayshade2-color', process.env[`${themePrefix}GRAY_SHADE2_COLOR`]);
        root.style.setProperty('--grayshade3-color', process.env[`${themePrefix}GRAY_SHADE3_COLOR`]);
        root.style.setProperty('--graydark-color', process.env[`${themePrefix}GRAY_DARK_COLOR`]);
        root.style.setProperty('--disabled-color', process.env[`${themePrefix}DISABLED_COLOR`]);
        root.style.setProperty('--notice-color', process.env[`${themePrefix}NOTICE_COLOR`]);
        root.style.setProperty('--lightBlue-color', process.env[`${themePrefix}LIGHT_BLUE_COLOR`]);
        root.style.setProperty('--white-color', process.env[`${themePrefix}WHITE_COLOR`]);
        root.style.setProperty('--grayshade4-color', process.env[`${themePrefix}GRAY_SHADE4_COLOR`]);
        root.style.setProperty('--grayshade5-color', process.env[`${themePrefix}GRAY_SHADE5_COLOR`]);
        root.style.setProperty('--btnhover', process.env[`${themePrefix}BUTTON_HOVER`]);
        root.style.setProperty('--btndisable', process.env[`${themePrefix}BUTTON_DISABLE`]);
        root.style.setProperty('--loaderbg', process.env[`${themePrefix}LOADER_BG`]);
        root.style.setProperty('--radioBorder', process.env[`${themePrefix}RADIO_BORDER`]);
        root.style.setProperty('--menuhover-color', process.env[`${themePrefix}MENU_HOVER_COLOR`]);
        root.style.setProperty('--imgopacity', process.env[`${themePrefix}IMAGE_OPACITY`]);
        root.style.setProperty('--lightblue-color', process.env[`${themePrefix}LIGHT_BLUE_GRAY`]);
        root.style.setProperty('--popupBtncolor', process.env[`${themePrefix}POPUP_BTN_COLOR`]);
        root.style.setProperty('--grey-highlight', process.env[`${themePrefix}GREY_HIGHLIGHT`]);
        root.style.setProperty('--blueLinkColor', process.env[`${themePrefix}BLUE_LINK_COLOR`]);
        root.style.setProperty('--lightGreyMessage', process.env[`${themePrefix}LIGHT_GREY_MESSAGE`]);
        root.style.setProperty('--chatLoaderone-color', process.env[`${themePrefix}CHAT_LOADER_ONE_COLOR`]);
        root.style.setProperty('--chatLoadertwo-color', process.env[`${themePrefix}CHAT_LOADER_TWO_COLOR`]);
        root.style.setProperty('--grayshade6-color', process.env[`${themePrefix}GRAY_SHADE6_COLOR`]);
       
      }

    return (
        <ThemeContext.Provider value={{ theme, selectedTheme, toggleTheme,setCustomProperties }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);